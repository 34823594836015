<template>
	<section class="content">
		<div class="alert alert-info alert-dismissible fade show" role="alert" v-if="true">
			<strong class="mr-1"><i class="fas fa-1x fa-bullhorn"></i> Pengumuman!</strong>
					Proses system auto approve topup membutuhkan waktu 10 menit - 30 menit. Jika status belum diapprove, bisa info kami dan kiriman bukti transfer via live chat.
					<!-- Per Tgl 11 Desember 2021, Ketentuan Retur dengan Pemilihan kurir JNE ada update, detail klik disini <a href="https://app.boscod.com/info/ketentuan" target="_blank">Info Ketentuan</a> -->
			<button type="button" class="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="flex justify-content-center">
			<div class="w-100 card">
				<div class="card-body">
					<div class="row">
						<div class="col-xl-3 col-md-3">
							<div class="card shadow-sm border rounded">
								<div class="card-body">
									<div class="text-muted mb-1 font-13 text-uppercase flex">
										Saldo Yang Tersedia
									</div>
									<h4 class="mb-1">
										<span> {{ formatRupiah(allSaldo.current) }} </span>
									</h4>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-md-3">
							<div class="card shadow-sm border rounded">
								<div class="card-body">
									<div class="text-muted mb-1 font-13 text-uppercase flex">
										Dalam Proses Penarikan
									</div>
									<h4 class="mb-1">
										<span> {{ formatRupiah(allSaldo.onWitdraw) }} </span>
									</h4>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-md-3">
							<div class="card shadow-sm border rounded">
								<div class="card-body">
									<div class="text-muted mb-1 font-13 text-uppercase flex">
										Saldo Pending
									</div>
									<h4 class="mb-1">
										<span> {{ formatRupiah(allSaldo.pending) }} </span>
									</h4>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-md-3">
							<div
								class="card"
								style="background-color: rgba(245, 245, 245, 0.4)"
							>
								<button
									type="button"
									v-on:click="topupSaldo"
									class="btn btn-primary btn-lg"
								>
									Topup Saldo
								</button>
								<button
									type="button"
									v-on:click="withdrawSaldo"
									class="btn btn-outline-success btn-lg mt-1"
									:disabled="loadingWithdraw"
								>
									Tarik Saldo	<span v-if="loadingWithdraw" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								</button>
							</div>
						</div>
					</div>
					<div class="row justify-content-end">
						<div class="col-12 col-sm-auto">
							<h4>Periode Laporan</h4>
						</div>

						<div class="col-12 col-sm-auto mr-auto">
							<div class="input-group mb-3">
								<input
									type="text"
									ref="daterange"
									class="form-control"
									style="border-right: 0"
								/>
								<div class="input-group-append">
									<div
										class="input-group-text"
										style="background-color: #fff"
									>
										<span><span class="fa fa-calendar"></span></span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-auto">
							<div class="btn-group" ref="toolbar">
								<button type="button" data-action="read" class="btn btn-default"><i
									class="fa fa-sync-alt"></i></button>
								<button type="button" :disabled="downloading" v-on:click="downloadXlsx"
									data-action="export-xlsx" class="btn btn-default"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
							</div>
						</div>
					</div>
					<div class="row" v-if="false">
						<div class="col-xl-3 col-md-3">
							<div class="card shadow-sm border rounded">
								<div class="card-body">
									<div class="text-muted mb-1 font-13 text-uppercase flex">
										Penarikan Selesai
									</div>
									<h4 class="mb-1">
										Rp<span> {{ allSaldo.afterWithdraw }} </span>
									</h4>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-md-3">
							<div class="card shadow-sm border rounded">
								<div class="card-body">
									<div class="text-muted mb-1 font-13 text-uppercase flex">
										Pendapatan COD
									</div>
									<h4 class="mb-1">
										Rp<span> {{ allSaldo.cod }} </span>
									</h4>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-md-3">
							<div class="card shadow-sm border rounded">
								<div class="card-body">
									<div class="text-muted mb-1 font-13 text-uppercase flex">
										Menghemat Ongkos Kirim
									</div>
									<h4 class="mb-1">
										Rp<span> {{ allSaldo.ongkirDisc }} </span>
									</h4>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-md-3">
							<div class="card shadow-sm border rounded">
								<div class="card-body">
									<div class="text-muted mb-1 font-13 text-uppercase flex">
										Ongkos Return Kirim
									</div>
									<h4 class="mb-1">Rp<span> 0 </span></h4>
								</div>
							</div>
						</div>
					</div>
					<div class="row" v-if="false">
						<div class="col-xl-3 col-md-3">
							<div class="card shadow-sm border rounded">
								<div class="card-body">
									<div class="text-muted mb-1 font-13 text-uppercase flex">
										Dikumpulkan Dari Pelanggan
									</div>
									<h4 class="mb-1">Rp<span> 0 </span></h4>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-md-3">
							<div class="card shadow-sm border rounded">
								<div class="card-body">
									<div class="text-muted mb-1 font-13 text-uppercase flex">
										Ongkos Kirim COD Yang Dibayar
									</div>
									<h4 class="mb-1">
										Rp<span> {{ allSaldo.ongkirCodDibayar }} </span>
									</h4>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-md-3">
							<div class="card shadow-sm border rounded">
								<div class="card-body">
									<div class="text-muted mb-1 font-13 text-uppercase flex">
										Pendapatan COD Bersih
									</div>
									<h4 class="mb-1">
										Rp<span> {{ allSaldo.incomeCodBersih }} </span>
									</h4>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-md-3">
							<div class="card shadow-sm border rounded">
								<div class="card-body">
									<div class="text-muted mb-1 font-13 text-uppercase flex">
										Ongkos Kirim Bersih Yang Dihemat
									</div>
									<h4 class="mb-1">Rp<span> 0 </span></h4>
								</div>
							</div>
						</div>
					</div>
					<div class="mb-3"></div>
					<div class="table-responsive">
						<table class="table table-hover" ref="tablesaldo" id="tblsaldo">
							<thead>
								<tr>
									<th>TANGGAL</th>
									<th>TIPE</th>
									<th>DESKRIPSI</th>
									<th>JUMLAH</th>
									<th>KREDIT</th>
									<th>SALDO</th>
									<th>STATUS</th>
									<th>INVOICE ID</th>
								</tr>
							</thead>
							<tbody @click="handleClick"></tbody>
						</table>
					</div>
				</div>
			</div>
			<!-- form saldo -->
			<div class="modal overflow-auto" tabindex="-1" role="dialog" ref="formSaldo" data-backdrop="static">
				<div class="modal-dialog" role="document">
					<div class="modal-content p-2">
						<form role="form" @submit.prevent="submitSaldo">
							<div class="modal-header">
								<h4 class="modal-title font-weight-bold">Top-up Saldo</h4>
								<button
									type="button"
									class="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="modal-body">
								<div class="form-group bg-topup border border-bottom-0 border-secondary-subtle shadow-sm p-3">
									<div class="row" style="row-gap: 1rem">
										<div class="col">
											<div>Saldo kamu saat ini <span class="text-sm" ref="btntoolTip2" @mouseover="mouseOver2" data-toggle="tooltip" data-placement="top" data-html="true" title="<h6>Gunakan Top-UP Saldo untuk melakukan pengiriman Non-COD.</h6>"><i class="fi fi-rr-info"></i></span></div>
											<div class="h4 font-weight-bold mt-1">{{ formatRupiah(allSaldo.current) }}</div>
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="form-control bos-alert-primary lh-2 d-flex align-items-center h-100">
										<div class="row">
											<div class="col-auto d-flex align-items-center">
												<i class="fi fi-rr-info d-flex align-items-center"></i>
											</div>
											<div class="col d-flex align-items-center">
												<div class="text-md">
													Minimal Top-up <span class="font-weight-bold">{{ formatRupiah(15000) }}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="row">
										<div class="col d-flex justify-content-between">
											<label class="font-weight-normal">Top-up berapa?</label>
										</div>
									</div>
									<currency-input
										class="form-control"
										currency="IDR"
										v-model="form.jumlah"
										required="required"
										placeholder="Jumlah"
										v-bind:class="{ 'is-invalid': topupErr }"
										onkeydown="return (event.ctrlKey || event.altKey 
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"
									/>
									<div class="row mt-2" v-if="topupErr">
										<div class="col text-right">
											<label class="font-weight-normal text-danger mb-0">{{ topupMsg }}</label>
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="row">
										<div class="col d-flex justify-content-between">
											<label>Transfer pakai apa?</label>
										</div>
									</div>
									<div v-for="(bankData,key) in totalBank">
										<div class="text-muted mb-2 font-13" :class="key == 'qris' ? 'text-uppercase' : 'text-capitalize'">{{key}}</div>
										<div class="row">
											<div class="col-12 col-sm-6" v-for="bank in bankData" v-bind:key="bank.rekening_id" v-bind:value="bank.rekening_id" v-bind:class="{ 'is-invalid': topupErr }" @click="setPaymentMethod(bank.payment_category, bank.bank_id, bank.service_fee, bank.service_fee_type)">
												<label class="w-100">
													<input type="radio" name="rekening_id" class="card-input-element d-none" v-model="form.rekening_id" :value="bank.rekening_id">
													<div class="card card-body mb-1 bg-light d-flex flex-row justify-content-between align-items-center" style="gap: 16px;">
														<div class="d-flex flex-column align-items-center" style="gap: 8px;">
															<img :src="getBankLogo(bank.code)" width="60px;" />
															<span class="text-muted weight-400" style="font-size:10px" v-if="bank.service_fee">Fee&nbsp;{{bank.service_fee_type != 'percent' ? formatRupiah(bank.service_fee) : (bank.service_fee + '%')}}</span>
														</div>
														<div class="d-flex flex-column align-items-center text-center" style="gap: 8px;">
															{{ getBankName(bank.code, bank.name) }}
														</div>
													</div>
												</label>
												<span
													class="invalid-feedback is-invalid" id="feedback-1" v-if="topupErr">
													{{ topupMsg }}
												</span>
											</div>
											<!-- <div class="col-6" >
											</div> -->
										</div>
									</div>
								</div>
								<div class="form-group mb-0">
									<div class="row justify-content-end">
										<div class="col-auto">
											<button type="button" class="btn btn-outline-warning btn-block" data-dismiss="modal">
												Batal
											</button>
										</div>
										<div class="col-auto">
											<button type="submit" :disabled="loading" class="btn btn-warning btn-block text-light" id="btn_saldo">
												Lanjutkan Pembayaran
												<span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<!-- form withdraw -->
			<div class="modal overflow-auto" tabindex="-1" role="dialog" ref="formWithdraw" data-backdrop="static">
				<div class="modal-dialog" role="document">
					<div class="modal-content p-2">
						<form role="form" @submit.prevent="loadPin">
							<div class="modal-header">
								<h4 class="modal-title font-weight-bold">Tarik Saldo</h4>
								<button
									type="button"
									class="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="modal-body">
								<div class="form-group" v-if="!enableWithdraw">
									<div class="form-control bos-alert-warning lh-2 d-flex align-items-center h-100">
										<div class="row">
											<div class="col-auto d-flex align-items-center">
												<i class="fi fi-rr-info d-flex align-items-center"></i>
											</div>
											<div class="col d-flex align-items-center">
												<div class="text-md">
													Mohon lengkapi data rekening terlebih dahulu. <div class="cursor-pointer text-primary font-weight-bold" v-on:click="setupRekening">Atur Rekening Disini</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div v-else>
									<div class="form-group bg-withdraw p-3 text-light">
										<div class="row" style="row-gap: 1rem">
											<div class="col">
												<div>Saldo yang dapat kamu tarik</div>
												<div class="h4 font-weight-bold mt-1">{{ formatRupiah(allSaldo.saldo) }}</div>
												<div v-if="allSaldo.retainedFundStr != 0">Saldo tertahan <span class="text-sm" ref="btntoolTip" @mouseover="mouseOver" data-toggle="tooltip" data-placement="top" data-html="true" title="<h6>Dana yang ditahan sebesar ongkir untuk paket-paket dalam proses retur</h6>"><i class="fi fi-rr-info"></i></span></div>
												<div v-if="allSaldo.retainedFundStr != 0" class="font-weight-bold mt-1">{{ formatRupiah(allSaldo.retainedFundStr) }}</div>
											</div>
											<div v-if="allSaldo.retainedFundStr != 0" class="col-auto ml-auto mt-auto">
												<button class="btn btn-sm btn-outline-light" v-on:click="loadRetainedFund">Lihat daftar paket</button>
											</div>
										</div>
									</div>
									<div class="form-group">
										<div class="form-control bos-alert-warning lh-2 d-flex align-items-center h-100">
											<div class="row">
												<div class="col-auto d-flex align-items-center">
													<i class="fi fi-rr-info d-flex align-items-center"></i>
												</div>
												<div class="col d-flex align-items-center">
													<div class="text-md">
														Lihat jadwal pencairan dana <a href="//boscod.com/syarat-ketentuan-withdraw/" target="_blank" class="font-weight-bold">Disini</a>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form-group">
										<div class="form-control bos-alert-primary lh-2 d-flex align-items-center h-100">
											<div class="row">
												<div class="col-auto d-flex align-items-center">
													<i class="fi fi-rr-info d-flex align-items-center"></i>
												</div>
												<div class="col d-flex align-items-center">
													<div class="text-md">
														Minimal dana yang dapat ditarik <span class="font-weight-bold">{{ formatRupiah(infoRekening.code == 'DANA' ? 20000 : 15000) }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form-group">
										<div class="row">
											<div class="col d-flex justify-content-between">
												<label>Informasi Rekening</label>
												<label class="control-label text-primary cursor-pointer" v-on:click="setupRekening" v-if="false">Ubah Rekening <i class="fi fi-rr-angle-right"></i></label>
											</div>
										</div>
										<div class="d-flex flex-column" style="gap: 0.5rem;">
											<div class="form-control h-100 py-3 border-grey cursor-pointer" style="background-color: #fff;border-width: 2px;" v-on:click="setupRekening">
												<div class="row align-items-center">
													<div class="col-auto d-flex justify-content-center align-items-center px-0 px-sm-2" style="max-width: 20%;max-height: 33.275px">
														<img :src="getBankLogo(infoRekening.code)" style="max-width: 100%;max-height: 60px;" />
													</div>
													<div class="col">
														<div>({{ infoRekening.name }}) {{ infoRekening.bank_rekening }}</div>
														<div>A/n {{ infoRekening.bank_atasnama.toUpperCase() }}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form-group">
										<div class="row">
											<div class="col d-flex justify-content-between">
												<label>Mau tarik tunai berapa?</label>
											</div>
										</div>
										<currency-input
											class="form-control"
											currency="IDR"
											v-model="form.jumlah"
											required="required"
											placeholder="Rp"
											:disabled="!enableWithdraw"
											v-bind:class="{ 'is-invalid': withdrawErr }"
											onkeydown="return (event.ctrlKey || event.altKey 
											|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
											|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
											|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"
										/>
										<div class="row mt-2" v-if="withdrawErr">
											<div class="col text-right">
												<label class="font-weight-normal text-danger mb-0">{{ withdrawMsg }}</label>
											</div>
										</div>
									</div>
									<div class="form-group">
										<div class="row">
											<div class="col-12">
												<div class="form-control h-100 px-0 pt-2 pb-3" style="border-width: 0;border-bottom-width: 2px;border-radius: 0;">
													<span class="font-weight-bold">GRATIS</span> biaya admin untuk penarikan dari <span class="font-weight-bold">Bank BCA dan Bank MANDIRI</span>
												</div>
											</div>
										</div>
										<div class="row" v-if="false">
											<div class="col-auto mr-1">
												<label class="font-weight-normal">Catatan :</label>
											</div>
											<ul class="col">
												<li>Biaya admin untuk penarikan {{ infoRekening.name }} adalah <span class="font-weight-bold">{{ !infoRekening.admin_fee || infoRekening.admin_fee == 0 ? 'Gratis' : formatRupiah(infoRekening.admin_fee) }}</span></li>
												<li>Biaya admin untuk penarikan BANK CENTRAL ASIA adalah <span class="font-weight-bold">Gratis</span></li>
											</ul>
										</div>
									</div>
									<div class="form-group mb-0">
										<div class="row justify-content-end">
											<div class="col-auto">
												<button type="button" class="btn btn-outline-warning btn-block" data-dismiss="modal">
													Tutup
												</button>
											</div>
											<div class="col-auto">
												<button type="submit" :disabled="(!enableWithdraw || loading)" class="btn btn-warning btn-block text-light" id="btn_saldo">
													Tarik Saldo
													<span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<!-- Preview after transaction -->
			<div class="modal overflow-auto" tabindex="-1" role="dialog" ref="detailTopup" id="detailTopup" data-backdrop="static">
				<div class="modal-dialog" role="document">
					<div class="modal-content p-2">
						<div class="modal-header">
							<h4 class="modal-title font-weight-bold">ID Transaksi #{{ saldo.invoice_code }}</h4>
							<button
								type="button"
								class="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="form-group">
								<div class="form-control d-flex justify-content-center align-items-center h-100 py-3">
									<div class="d-flex flex-column text-center text-dark" style="gap: 6px">
										<div>Waktu transfer kamu</div>
										<div class="h3 d-none d-sm-block font-weight-bold">{{ countDown }}</div>
										<div class="h5 d-block d-sm-none font-weight-bold">{{ countDown }}</div>
										<div>Jika lebih dari waktu maka transaksi otomatis dibatalkan</div>
									</div>
								</div>
							</div>
							<div class="form-group">
								<div class="form-control h-100 px-0 py-2" style="border-width: 0;border-bottom-width: 2px;border-radius: 0;">
									<div class="row align-items-center">
										<div class="col">
											<div class="d-flex flex-column" style="gap: 4px">
												<div>{{ saldo.image_qr ? 'Transfer QR Code' : saldo.payment_method == 'winpay' ? saldo.payment_method_winpay : saldo.bank }}</div>
												<div class="h5 font-weight-bold mb-0">{{ saldo.image_qr ? saldo.payment_method_winpay : saldo.atas_nama ? saldo.atas_nama : 'Modern Store' }}</div>
											</div>
										</div>
										<div class="col-3">
											<img class="w-100" :src="saldo.logo" />
										</div>
									</div>
								</div>
							</div>
							<div class="form-group" v-if="saldo.image_qr">
								<div class="row">
									<div class="col-auto m-auto">
										<img class="w-100" :src="saldo.image_qr" />
									</div>
								</div>
							</div>
							<div class="form-group" v-if="saldo.payment_code != null || saldo.rekening != null">
								<div class="row align-items-center">
									<div class="col">
										<div class="d-flex flex-column" style="gap: 4px">
											<div>{{ saldo.payment_method == 'winpay' ? "Nomor Pembayaran" : "Nomor Rekening" }}</div>
											<div class="h4 font-weight-bold">{{ saldo.payment_method == 'winpay' ? saldo.payment_code : saldo.rekening }}</div>
										</div>
									</div>
									<div class="col-auto">
										<button class="btn btn-outline-warning" ref="btnRekening" @click="copyRekening">Salin</button>
									</div>
								</div>
							</div>
							<div class="form-group">
								<div class="row align-items-center">
									<div class="col-auto">
										<div class="d-flex flex-column" style="gap: 4px">
											<div :class="{ 'cursor-pointer' : saldo.unique_code > 0 || saldo.admin_fee > 0 }" @click="toggleDetail">Nominal Transfer Keseluruhan <small class="ml-1" v-if="saldo.unique_code > 0 || saldo.admin_fee > 0"><i class="fi" :class="( showDetail ? 'fi-rr-angle-up' : 'fi-rr-angle-down' )"></i></small></div>
											<div class="d-flex justify-content-between text-secondary" v-if="showDetail && (saldo.unique_code > 0 || saldo.admin_fee > 0)">
												<div>Nominal</div>
												<div>{{ formatRupiah(saldo.amount) }}</div>
											</div>
											<div class="d-flex justify-content-between text-secondary" v-if="showDetail && saldo.unique_code > 0">
												<div>Nomor Unik</div>
												<div>{{ formatRupiah(saldo.unique_code) }}</div>
											</div>
											<div class="d-flex justify-content-between text-secondary" v-if="showDetail && saldo.admin_fee > 0">
												<div>Biaya Admin</div>
												<div>{{ formatRupiah(saldo.admin_fee) }}</div>
											</div>
											<div class="h4 font-weight-bold mb-0">{{ formatRupiah(parseInt(saldo.debit) + parseInt(saldo.admin_fee ? saldo.admin_fee : 0)).slice(0, -3) }}<span class="text-warning">{{ formatRupiah(parseInt(saldo.debit) + parseInt(saldo.admin_fee ? saldo.admin_fee : 0)).substr(-3) }}</span></div>
										</div>
									</div>
									<div class="col-auto ml-auto">
										<button class="btn btn-outline-warning" ref="btnNominal" @click="copyNominal">Salin</button>
									</div>
								</div>
							</div>
							<div v-if="saldo.payment_method != 'winpay'" class="form-group mb-4">
								<div class="form-control bos-alert-warning lh-2 d-flex align-items-center h-100">
									<div class="row">
										<div class="col-auto d-flex align-items-center">
											<i class="fi fi-rr-info d-flex align-items-center"></i>
										</div>
										<div class="col d-flex align-items-center">
											<div class="text-md">
												Pastikan nominal benar sampai 3 digit terakhir
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group">
								<div class="row justify-content-end">
									<div class="col-auto">
										<button class="btn btn-outline-warning" @click="loadInstruction" :disabled="loadingTopup">
											Cara Pembayaran <span v-if="loadingTopup" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
									</div>
									<div class="col-auto">
										<button class="btn btn-warning text-light" data-dismiss="modal">Selesai</button>
									</div>
								</div>
							</div>
							<!-- <div class="card bg-for-light">
								<span
									style="font-size: 15px; font-weight: 700; text-align: left"
									>Detail pembayaran</span
								>
								<table style="width: 100%; text-align: left">
									<tr class="items">
										<td class="w-100">Pembayaran</td>
										<td class="w-auto d-flex justify-content-between">
											<div class="mr-1">IDR</div>
											<div>{{ formatRupiah(saldo.amount, ' ') }}</div>
										</td>
									</tr>
									<tr class="items" v-if="saldo.payment_method != 'winpay'">
										<td class="w-100">Nomor unik</td>
										<td class="w-auto d-flex justify-content-between">
											<div class="mr-1">IDR</div>
											<div>{{ formatRupiah(saldo.unique_code, ' ') }}</div>
										</td>
									</tr>
									<tr class="items" v-else>
										<td class="w-100">Admin fee</td>
										<td class="w-auto d-flex justify-content-between">
											<div class="mr-1">IDR</div>
											<div>{{ formatRupiah(saldo.admin_fee, ' ') }}</div>
										</td>
									</tr>
									<tr class="border-top">
										<td class="w-100">Jumlah transfer</td>
										<td class="w-auto d-flex justify-content-between">
											<div class="mr-1">IDR</div>
											<div>{{ formatRupiah(parseInt(saldo.debit) + parseInt(saldo.admin_fee ? saldo.admin_fee : 0), ' ') }}</div>
										</td>
									</tr>
								</table>
							</div>
							<div class="text-center">
								<h6 class="mb-3">Transfer ke:</h6>
								<img class="w-25 m-auto" :src="saldo.image_qr ? getBankLogo(saldo.image_qr) : saldo.payment_method == 'winpay' ? getBankLogo(saldo.payment_method_code) : getBankLogo(saldo.code)" />
								<h5 class="mt-2 font-weight-bold">{{saldo.payment_method != 'winpay' ? saldo.bank : saldo.payment_method_winpay}}</h5>
								<h6 style="color: #2e47ba;">
									{{saldo.payment_method != 'winpay' ? saldo.rekening : saldo.payment_code}}
									<button ref="btnCopy" class="btn btn-sm btn-default" data-toggle="tooltip" data-placement="right" title="Copy to clipboard" type="button">
										<i class="fa fa-copy"></i>
									</button>
								</h6>
								<h6>{{saldo.atas_nama}}</h6>
								<h6 class="mt-3">Total yang ditransfer:</h6>
								<h4 style="color: #2e47ba;">{{ formatRupiah(parseInt(saldo.debit) + parseInt(saldo.admin_fee ? saldo.admin_fee : 0)) }}</h4>
							</div> -->
						</div>
					</div>
				</div>
			</div>
			<div class="modal overflow-auto" tabindex="-1" role="dialog" ref="howTopup" data-backdrop="static">
				<div class="modal-dialog" role="document">
					<div class="modal-content p-2">
						<div class="modal-header">
							<h4 class="modal-title font-weight-bold">Cara Pembayaran</h4>
							<button
								type="button"
								class="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="form-group">
								<div class="accordion" id="accordionTopup">
									<div v-for="(instruction, key) in instructions">
										<button class="btn btn-link btn-block font-weight-bold text-dark d-flex justify-content-between px-0 border-0 my-2" data-toggle="collapse" :data-target="'#collapse' + key" :aria-expanded="!key" :aria-controls="'collapse' + key">
											{{ instruction.title }} <i class="fi" :class="( !key ? 'fi-rr-angle-up' : 'fi-rr-angle-down' )"></i>
										</button>
										<div :id="'collapse' + key" class="bos-list pt-1 collapse" :class="{ 'show' : !key }" data-parent="#accordionTopup">
											<ol>
												<li v-for="description in instruction.data">
													<div>{{ description }}</div>
												</li>
											</ol>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group">
								<div class="row justify-content-end">
									<div class="col-auto">
										<button class="btn btn-outline-warning" data-dismiss="modal" data-toggle="modal" data-target="#detailTopup">Kembali</button>
									</div>
									<div class="col-auto">
										<button class="btn btn-warning text-light" data-dismiss="modal">Tutup</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Pin :showPin="showPin" :loadingPin="loadingPin" :messagePin="messagePin" v-on:change="submitWithdraw" />
	</section>
</template>
<script>
import { authFetch, auth, createTable, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import { CurrencyInput } from "vue-currency-input";
import Daterangepicker from "daterangepicker";
import moment from "moment";
import "daterangepicker/daterangepicker.css";
import Pin from "@/dialog/Pin";
export default {
	name: "Saldo",
	data() {
		return {
			dateStart: "",
			dateEnd: "",
			errors: [],
			totalBank: [],
			method: "",
			form: {
				jumlah: 0,
				rekening_id: "",
				admin_fee: 0,
				admin_fee_type: '',
				payment_category: '',
				payment_channel: ''
			},
			saldo: {},
			currentSaldo: 0,
			total: "",
			roles: "",
			role_id: "",
			showPin: true,
			loadingPin: false,
			messagePin: 'ubah rekening',
			withdrawErr: false,
			withdrawMsg: "",
			topupErr: false,
			topupMsg: "",
			loadingTopup: false,
			showDetail: false,
			countDown: '00 Jam : 00 Menit : 00 Detik',
			myInterval: undefined,
			instructions: {},
			allSaldo: {
				current: 0,
				saldo: 0,
				onWitdraw: 0,
				pending: 0,
				afterWithdraw: 0,
				cod: 0,
				ongkirDisc: 0,
				ongkirCodDibayar: 0,
				incomeCodBersih: 0,
				retainedFundStr: 0,
			},
			infoRekening: null,
			loadingWithdraw: false,
			suspect:false,
			enableWithdraw: null,
			loading: false,
			downloading: false,
			filter: {},
			pinCheck: false,
			max_topup_trx: 0,
			max_topup_daily: 0,
			max_withdraw_trx: 0,
			max_withdraw_daily: 0,
			show_download: true
		};
	},
	created: function () {
		this.roles = this.$route.meta.roles;
		// console.log("load initial data", this.$route);
		this.dateStart = moment().add(-1, 'M').startOf("month").format("YYYY-MM-DD");
		this.dateEnd = moment().format("YYYY-MM-DD");
		if (this.$route.params.filter)
			this.filter = this.$route.params.filter;
		if (this.filter.d1 && this.filter.d2) {
			this.d1 = moment(this.filter.d1);
			this.d2 = moment(this.filter.d2);
		}
		if (this.dateStart && this.dateEnd) {
			this.d1 = moment(this.dateStart);
			this.d2 = moment(this.dateEnd);
		}
		if (Object.keys(this.filter).length < 1) {
			if (sessionStorage.filterData) this.filter = JSON.parse(sessionStorage.filterData);
		} else {
			sessionStorage.filterData = JSON.stringify(this.filter);
			sessionStorage.filterStatus = 1;
		}
		this.filter.datatype = this.data_type;
		this.filter.d1 = !this.filter.d1 ? moment().add(-1, 'M').startOf("month").format("YYYY-MM-DD") : this.filter.d1;
		this.filter.d2 = !this.filter.d2 ? moment().format("YYYY-MM-DD") : this.filter.d2;
		this.loadSaldo();
	},
	methods: {
		copyRekening() {
			var self = this;
			navigator.clipboard.writeText(this.saldo.payment_method != 'winpay' ? this.saldo.rekening : this.saldo.payment_code).then(function() {
				if(self.saldo.payment_method != 'winpay'){
					$(self.$refs.btnRekening).attr('title', 'Nomor Rekening berhasil disalin!').tooltip('_fixTitle').tooltip('show').attr('title','Salin ke papan klip').tooltip('_fixTitle')
				} else {
					$(self.$refs.btnRekening).attr('title', 'Nomor Pembayaran berhasil disalin!').tooltip('_fixTitle').tooltip('show').attr('title','Salin ke papan klip').tooltip('_fixTitle')
				}
			}, function(err) {
				console.error(err);
			});
		},
		copyNominal() {
			var self = this;
			navigator.clipboard.writeText(parseInt(this.saldo.debit) + parseInt(this.saldo.admin_fee ? this.saldo.admin_fee : 0)).then(function() {
				$(self.$refs.btnNominal).attr('title', 'Nominal Transfer berhasil disalin!').tooltip('_fixTitle').tooltip('show').attr('title','Salin ke papan klip').tooltip('_fixTitle')
			}, function(err) {
				console.error(err);
			});
		},
		mouseOver: function(e){
			let ev = this.$refs;
			$(ev.btntoolTip).tooltip('show');
			// $(ev.btntoolTip).css("cursor", "help");
		},
		mouseOver2: function(e){
			let ev = this.$refs;
			$(ev.btntoolTip2).tooltip('show');
			// $(ev.btntoolTip).css("cursor", "help");
		},
		toggleDetail() {
			if (this.saldo.unique_code > 0 || this.saldo.admin_fee > 0) {
				this.showDetail = !this.showDetail;
			} else {
				this.showDetail = false;
			}
		},
		getBankLogo(code) {
			if (!code) {
				return '';
			} else if (code.includes('http')) {
				return code
			} else {
				// const fileName = code.toLowerCase();
				// return require(`../assets/img/bank/${fileName}.png`) // the module request
				const fileName = code.toUpperCase();
				return `https://d3b8xio63zxbsy.cloudfront.net./images/logo/bank/${fileName}.svg` // the module request
			}
		},
		getBankName(code, name) {
			if (code.includes('http')) {
				return name
			} else {
				return code
			}
		},
		setupRekening: function(ev){
			ev.preventDefault()
			const e = this.$refs;
			$(e.formWithdraw).modal("hide");
			this.$router.push({
					path: "/settings/rekening"
				});
			return false;
		},
		loadRetainedFund: function(ev){
			ev.preventDefault()
			const e = this.$refs;
			$(e.formWithdraw).modal("hide");
			this.$router.push({
					path: "/saldo/withdraw/retained"
				});
			return false;
		},
		loadRekening: function () {
			authFetch("/payment/saldo/rekening")
				.then((res) => {
						if (res.status === 201) {} else if (res.status === 400) {}
						return res.json();
				})
				.then((js) => {
						this.infoRekening = js.data;
						// console.log('tes',js.data);
						if(js.data){
							this.enableWithdraw = true;
							if(js.data.marking == 'suspect' || js.data.marking == 'suspect rekening')
								this.suspect = true;
							}
						else this.enableWithdraw = false;
				});
		},
		withdrawSaldo: function () {
			this.pinCheck = true;
			this.loadingWithdraw = true;
			let tipe = 'withdraw';

			authFetch("/payment/saldo/bank?pin="+ this.pinCheck +"&tipe="+tipe)
			.then((res) => {
					if (res.status === 201) {} else if (res.status === 400) {}
					return res.json();
			})
			.then((js) => {
				if (!js.status) {
					Swal.fire({
						title: js.msg, 
						icon: 'warning', 
						confirmButtonColor: '#FF7203',
						showCloseButton: true,
					}).then((result) => {
						if (result.isConfirmed) {
							if (js.tipe == 'pin') {
								this.$router.push({ path: "/settings/pin"});
							} else if (js.tipe == 'ktp') {
								this.$router.push({ path: "/verification"});
							}
						}
					});
				} else {
					this.showWithdraw();
				}
				this.loadingWithdraw = false;
			});
			authFetch("/payment/saldo/max_withdraw")
				.then((res) => {
						if (res.status === 201) {} else if (res.status === 400) {}
						return res.json();
				})
				.then((js) => {
						this.max_withdraw_trx = parseInt(js.data.max_withdraw_trx);
						this.max_withdraw_daily = parseInt(js.data.max_withdraw_daily);
				})
		},
		loadSaldo: function () {
			var filter='';
			filter+='d1='+this.dateStart;
			filter+='&d2='+this.dateEnd;
			// console.log(filter)
			authFetch("/payment/saldo/allsaldo?"+filter).then((res) => {
				res.json().then((json) => {
					this.currentSaldo = json.data.current;
					this.allSaldo = json.data;
					this.allSaldo.current = this.allSaldo.current;
					this.allSaldo.saldo = this.allSaldo.saldo;
					this.allSaldo.onWitdraw = this.allSaldo.onWitdraw;
					this.allSaldo.pending = this.allSaldo.pending;
					this.allSaldo.afterWithdraw = this.allSaldo.afterWithdraw;
					this.allSaldo.cod = this.allSaldo.cod;
					this.allSaldo.ongkirDisc = this.allSaldo.ongkirDisc;
					this.allSaldo.ongkirCodDibayar = this.allSaldo.ongkirCodDibayar;
					this.allSaldo.incomeCodBersih = this.allSaldo.incomeCodBersih;
					this.allSaldo.retainedFundStr = this.allSaldo.retainedFund;
				});
			});
		},
		onPeriode() {
			this.tbl.api().ajax.reload();
		},
		topupSaldo: function () {
				const e = this.$refs;
				this.topupErr = false;
				this.topupMsg = "";

				authFetch("/payment/saldo/max_topup")
				.then((res) => {
						if (res.status === 201) {} else if (res.status === 400) {}
						return res.json();
				})
				.then((js) => {
						this.max_topup_trx = parseInt(js.data.max_topup_trx);
						this.max_topup_daily = parseInt(js.data.max_topup_daily);
				})
				
				let tipe = 'topup';
				authFetch("/payment/saldo/bank?pin="+ this.pinCheck +"&tipe="+tipe)
				.then((res) => {
						if (res.status === 201) {} else if (res.status === 400) {}
						return res.json();
				})
				.then((js) => {
						this.totalBank = js.data;
						$(e.formSaldo).modal("show");
				});
		},
		showWithdraw: function () {
			authFetch("/shipper/isset_pin")
			.then((res) => {
				if (res.status === 201) {
				} else if (res.status === 400) {
				}
				return res.json();
			})
			.then((js) => {
				if (!js.success) {
					this.showPin = !this.showPin;
				} else {
					const e = this.$refs;
					this.withdrawErr = false;
					this.withdrawMsg = "";
					$(e.formWithdraw).modal("show");
				}
			});
		},
		toPrice(price, unique) {
			let saldo = parseInt(price) + parseInt(unique);
			// let reverse = saldo.toString().split("").reverse().join(""),
			//	 ribuan = reverse.match(/\d{1,3}/g);
			// ribuan = ribuan.join(".").split("").reverse().join("");
			return formatCurrency(saldo);
		},
		formatRupiah(saldo, format) {
			return (format ? format : 'Rp\xa0') + formatCurrency(saldo);
		},
		submitSaldo: function (ev) {
			this.$gtag.event(`click`, {
				'event_name': 'Top-up'
			});
			if (this.form.jumlah < 15000) {
				this.topupMsg = "Jumlah Top-up minimal Rp 15.000";
				this.topupErr = true;
				return false;
			} else if (this.form.jumlah > this.max_topup_trx) {
				this.topupMsg = "Maximal Top-up " + this.formatRupiah(this.max_topup_trx);
				this.topupErr = true;
				return false;
			} else if (!this.form.rekening_id) {
				this.topupMsg = "Bank tujuan belum dipilih";
				this.topupErr = true;
				return false;
			}
			const e = this.$refs;
			var data = Object.keys(this.form)
				.map(
					(key) =>
						encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
				)
				.join("&");
			if (this.form.admin_fee_type == 'percent') {
				this.form.admin_fee = Math.ceil(parseInt(this.form.jumlah) * (parseFloat(this.form.admin_fee)/100))
			}
			var data = {
				jumlah: this.form.jumlah,
				rekening_id: this.form.rekening_id,
				admin_fee: this.form.admin_fee,
				payment_category: this.form.payment_category,
				payment_channel: this.form.payment_channel
			}
			// var setmethod = this.form.id ? "PUT" : "POST";
			var urlSubmit = "/payment/saldo";
			// if (this.method == "PUT")
			//	 urlSubmit = "/order/address/" + this.form.id;

			this.loading = true;
			authFetch(urlSubmit, {
				method: "POST",
				body: JSON.stringify(data),
			})
				.then((res) => {
					this.loading = true;
					if (res.status === 201) {
					} else if (res.status === 400) {
					}
					return res.json();
				})
				.then((js) => {
					this.errors = [];
					this.loading = false;
					if (js.success) {
						this.saldo = js.data;
						let price = this.toPrice(js.data.debit, js.data.unique_code);
						this.total = price;
						this.loadSaldo();
						this.countDownTimer(this.saldo.created_on);
						this.showDetail = false;
						$(e.detailTopup).modal("show");
					} else {
						Swal.fire(
							js.error_type ? "Perhatian<br>Limit Top Up Harian bosCOD " + this.formatRupiah(this.max_topup_daily) : '',
							js.desc ? js.desc : "Proses pengajuan top up gagal",
							"error"
						);
					}
					this.tbl.api().ajax.reload();
					this.form = {};
					$(e.formSaldo).modal("hide");
				});
			ev.preventDefault();
		},
		loadPin: function (ev) {
			if (this.form.jumlah > this.max_withdraw_trx) {
				this.withdrawMsg = "Maksimal tarik tunai " + this.formatRupiah(this.max_withdraw_trx);
				this.withdrawErr = true;
			} else if (this.form.jumlah > this.allSaldo.saldo) {
				this.withdrawMsg = "Saldo yang dapat ditarik " + this.formatRupiah(this.allSaldo.saldo);
				this.withdrawErr = true;
			} else if ((this.infoRekening.bank_id == 94 && this.form.jumlah < 20000) || (this.infoRekening.bank_id != 94 && this.form.jumlah < 15000)) {
				this.withdrawMsg = "Minimal tarik tunai " + this.formatRupiah(this.infoRekening.bank_id == 94 ? 20000 : 15000);
				this.withdrawErr = true;
			} else {
				const e = this.$refs;
				if(this.showPin){
					$(e.formWithdraw).modal("hide");
				}
				this.withdrawMsg = "";
				this.withdrawErr = false;
				this.showPin = !this.showPin;
			}
			ev.preventDefault();
		},
		submitWithdraw: function (pinSet) {
			this.loadingPin = true;
			this.$gtag.event(`click`, {
				'event_name': 'Tarik'
			});
			const e = this.$refs;
			this.form.admin_fee = !this.infoRekening.admin_fee || this.infoRekening.admin_fee == 0 ? 0 : this.infoRekening.admin_fee;

			var data = Object.keys(this.form)
				.map(
					(key) =>
						encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
				)
				.join("&");
			data = data + '&pin_no=' + pinSet;
			// var setmethod = this.form.id ? "PUT" : "POST";
			var urlSubmit = "/payment/saldo/withdraw";
			// if (this.method == "PUT")
			//	 urlSubmit = "/order/address/" + this.form.id;
			this.loading = true;
			authFetch(urlSubmit, {
				method: "POST",
				body: data,
			})
				.then((res) => {
					this.loading = true;
					if (res.status === 201) {
					} else if (res.status === 400) {
					}
					return res.json();
				})
				.then((js) => {
					this.loadingPin = false;
					this.loading = false;
					this.withdrawErr = false;
					this.withdrawMsg = "";
					this.errors = [];
					if (js.success) {
						Swal.fire(
							"Pemberitahuan",
							"Proses pengajuan penarikan dana berhasil, silakan tunggu proses dari admin",
							"success"
						);
						this.loadSaldo();
						$(e.formWithdraw).modal("hide");
						this.tbl.api().ajax.reload();
						this.form = {};
					} else {
						Swal.fire(
							"Pemberitahuan",
							js.desc ? js.desc : "Proses pengajuan penarikan dana gagal",
							"error"
						);
					}
				});
		},
		handleClick(e) {
			if (e.target.matches(".link-role")) {
				if (this.saldo.id != e.target.dataset.id) {
					authFetch("/payment/saldo/" + e.target.dataset.id).then((res) => {
						res.json().then((json) => {
							this.saldo = json;
							let price = this.toPrice(json.debit, json.unique_code);
							this.total = price;
							this.countDownTimer(this.saldo.created_on);
							this.showDetail = false;
							$(this.$refs.detailTopup).modal("show");
						});
					});
					return false;
				} else {
					$(this.$refs.detailTopup).modal("show");
				}
			}
			// if (e.target.matches("button")) {
			//	 console.log(e.target);
			//	 return false;
			// }
		},
		countDownTimer (params) {
			clearInterval(this.myInterval);
			var eventTime = moment(params,'YYYY-MM-DD HH:mm:ss+07').add(1, 'days').format('X');
			var currentTime = moment(params,'YYYY-MM-DD HH:mm:ss+07').format('X');
			
			console.log('----- -----')
			console.log('currentTime',currentTime)
			console.log('eventTime',eventTime);

			var diffTime = parseInt(eventTime) - parseInt(currentTime);
			if (diffTime < 1) {
				// Transaksi Anda telah melebihi batas waktu pembayaran
				this.countDown = '00 Jam : 00 Menit : 00 Detik';
				return false;
			}
			var duration = moment.duration(diffTime * 1000, 'milliseconds');
			var interval = 1000;
			this.myInterval = setInterval(() =>{
					duration = moment.duration(duration - interval, 'milliseconds');
					this.countDown = moment.utc(duration	- interval).format('hh [Jam] : mm [Menit] : ss [Detik]');
					if (duration.hours() == 0 && duration.minutes() == 0 && duration.seconds() == 0) {
							clearInterval(this.myInterval);
							this.countDown = '00 Jam : 00 Menit : 00 Detik';
					}
			}, interval);
		},
		loadInstruction() {
			if (this.instructions.bank != null && (this.instructions.bank == this.saldo.payment_method_code || this.instructions.bank == this.saldo.code)) {
				$(this.$refs.detailTopup).modal("hide");
				$(this.$refs.howTopup).modal("show");
			} else {
				this.loadingTopup = true;
				authFetch("/payment/saldo/instruction?bank=" + (this.saldo.payment_method == 'winpay' ? this.saldo.payment_method_code : this.saldo.code)).then((res) => {
					res.json().then((json) => {
						this.instructions = json.data;
						this.instructions.bank = this.saldo.payment_method == 'winpay' ? this.saldo.payment_method_code : this.saldo.code;
						$(this.$refs.detailTopup).modal("hide");
						$(this.$refs.howTopup).modal("show");
						this.loadingTopup = false;
					});
				});
			}
		},
		downloadXlsx: function (e) {
			if(this.show_download == false){
				Swal.fire({
					title: "Pemberitahuan",
					text: "Batas download maksimal 3 bulan terakhir.",
					icon: "warning"
				});
				return;
			}
			this.$gtag.event(`click`, {
				'event_name': 'Download Saldo'
			});
			this.userInfo = auth.user();
			this.downloading = true;
			var table = $('#tblsaldo').DataTable();
			var length = table.page.info().recordsTotal;
			
			if (length > 20000) {
				Swal.fire("Proses gagal", `Maksimal data sebanyak kurang lebih 20.000 data.`, "error");
				this.downloading = false;
				return false;
			}
			
			var filter = JSON.parse(JSON.stringify(this.filter));
					filter.q = $("input[name=q]").val();
					filter.category = $("select[name=category]").val();

			// console.log('FEELTER', filter);
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");
			// console.log('FILTER', data);
			this.loadingContent = true;
			authFetch("/report/saldo/excell/" + this.userInfo.id, {
				method: "POST",
				body: data,
			})
			.then((response) => {
				if (response.status === 201) {
				} else if (response.status === 400) {
				} else if (response.status === 500){
					Swal.fire("Timeout!", ``, "error");
					this.loading2 = false;
					this.msg = '';
					return false;
				}

				return response.blob();
			})
			.then((blob) => {
				setTimeout(() => {
					this.downloading = false;
				}, 1000);
				this.loadingContent = false;
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = "data.xlsx";
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove(); //afterwards we remove the element again
			});
		},
		setPaymentMethod(cat, code, fee, feeType) {
			this.form.payment_category = cat 
			this.form.payment_channel = code
			this.form.admin_fee_type = feeType
			this.form.admin_fee = fee
		}
	},
	mounted() {
		const e = this.$refs;
		$(e.btnRekening).tooltip().on('mouseleave',function(){$(this).tooltip('hide')})
		$(e.btnNominal).tooltip().on('mouseleave',function(){$(this).tooltip('hide')})
		const self = this;
		new Daterangepicker(this.$refs.daterange, {
			startDate: moment().add(-1, 'M').startOf("month"),
			endDate: moment(),
			locale: {
					format: 'DD/MM/YYYY'
			}
		}, function (d1, d2) {
			self.dateStart = d1.format("YYYY-MM-DD");
			self.dateEnd = d2.format("YYYY-MM-DD");
			self.filter.d1 = d1.format("YYYY-MM-DD");
			self.filter.d2 = d2.format("YYYY-MM-DD");
			self.onPeriode();
			self.loadSaldo();
		});
		$(e.formWithdraw).on("show.bs.modal", (e) => {
				if(this.infoRekening==null)
				{
						// console.log('load info rekening');
						this.loadRekening();
				}
		});
		this.tbl = createTable(e.tablesaldo, {
			title: "",
			roles: this.$route.params.roles,
			toolbarEl: e.toolbar,
			selectedRowClass: "",
			serverSide: true,
			frame: false,
			scrollX: false,
			processing: true,
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
			displayLength: 10,
			lengthChange: true,
			sort:false,
			ajax: "/payment/saldo",
			paramData: function (d) {
				d.d1 = self.dateStart;
				d.d2 = self.dateEnd;
			},
			columns: [
				{ data: "deposit_date" },
				{ data: "trans_type" },
				{ data: "note", sortable: false },
				{ 
					data: "debit", 
					class:'text-right',
					render: function(data, type, row, meta) {
						if((!data || data==0) && row.credit > 0)data = -row.credit;
						return formatCurrency(data);
					}
				},
				{ 
					data: "credit", 
					visible:false,
					class:'text-right',
					render: function(data, type, row, meta) {
						return formatCurrency(data);
					}
				},
				{ data: "saldo" ,
					class:'text-right',
					render: function(data, type, row, meta) {
						return formatCurrency(data);
					}
					},
				{ data: "status", render: function (data, type, row, meta) {
						let status = '';
						if(row.active < 1)
						{
								return `<span class="badge badge-danger">DITOLAK</span>`;
						} else {
							if (row.status == 0) {
								status = `<span class="badge badge-light">REQUEST</span>`;
							} else if (row.status == 1) {
								status = `<span class="badge badge-success">SUKSES</span>`;
							} else if (row.status == 2)  {
								status = `<span class="badge badge-info">PROSES</span>`;
							} else if (row.status == 3)  {
								status = `<span class="badge badge-warning text-light">PENDING</span>`;
							}else {
								status = `<span class="badge badge-info">PROSES</span>`;
							}
						}
						return status;
				} },
				{ data: "invoice_code" },
			],
			filterBy: [2, 7],
			rowCallback: function (row, data) {
				
				if (data.trans_type == 0) {
					if(data.status == 0)$("td:eq(6)", row).html(
						'<a class="link link-role" data-id="' +
							data.id +
							'" style="cursor:pointer;">' +
							data.invoice_code +
							"</a>"
					);
					$("td:eq(1)", row).html(
						`<span class="badge badge-info">TOP UP</span>`
					);
				} else if (data.trans_type == 1) {
					if(data.debit > 0)
					$("td:eq(1)", row).html(
						`<span class="badge badge-info">REKONSILIASI</span>`
					);

					else $("td:eq(1)", row).html(
						`<span class="badge badge-danger">REKONSILIASI</span>`
					);
				} else if (data.trans_type == 11) {
					$("td:eq(1)", row).html(
						`<span class="badge badge-danger">WITHDRAW</span>`
					);
					//$("td:eq(3)", row).html(data.debit ? data.debit : -data.credit);
				} else {
					$("td:eq(1)", row).html("");
				}
			},
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
		});
	},
	watch:{
		'dateStart':function(val){
			if(moment(val,"YYYY-MM-DD").format('x') < moment().subtract('3', 'month').format('x')){
				this.show_download = false;
			}else{
				this.show_download = true;
			}
		},
	},
	components: {
		Pin,
		CurrencyInput
	},
};
</script>
<style scoped>
.bg-topup {
	background-image: url("~@/assets/img/bg-topup.svg");
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: bottom;
	border-radius: 6px;
}

.bg-withdraw {
	background-color: #FF7203;
	background-image: url("~@/assets/img/bg-withdraw.svg");
	background-repeat: no-repeat;
	background-size: 100%;
	border-radius: 6px;
}

.card-input-element+.card {
	height: calc(36px + 2*1rem);
	color: #E05119;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 2px solid transparent;
	border-radius: 4px;
}

.card-input-element+.card:hover {
	cursor: pointer;
}

.card-input-element:checked+.card {
	border: 2px solid #E05119;
	-webkit-transition: border .3s;
	-o-transition: border .3s;
	transition: border .3s;
}

.card-input-element:checked+.card::after {
	content: '\2713';
	color: #E05119;
	font-size: 24px;
	-webkit-animation-name: fadeInCheckbox;
	animation-name: fadeInCheckbox;
	-webkit-animation-duration: .5s;
	animation-duration: .5s;
	-webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.card-input-element+.card::after {
	content: '\2713';
	color: rgba(0, 0, 0, 0);
	font-size: 24px;
}

button.collapsed i.fi.fi-rr-angle-up:before {
    content: "\f131" !important;
}

button:not(.collapsed) i.fi.fi-rr-angle-down:before {
    content: "\f13c" !important;
}

.bos-list ol {
	padding-inline-start: 20px;
	margin-bottom: 0;
	counter-reset: myCounter;
}
.bos-list ol li {
	margin-bottom: 8px;
}
.bos-list ol li {
	list-style: none;
	padding-left: 8px;
	position: relative;
}
.bos-list ol li:before {
	position: absolute;
	left: -20px;
	margin-top: -2.5px;
	counter-increment: myCounter;
	content: counter(myCounter);
	line-height: 20px;
	transition: all .2s;
	color: #62676F;
	background: #E6E6E6;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.description-text{
	font-size: 15px;
}

.btn-circle {
		width: 15px;
		height: 15px;
		padding: 0px 0px;
		border-radius: 10px;
		text-align: center;
		font-size: 10px;
		line-height: 1.42857;
}

.center {
	/* position: absolute; */
	top: 50%;
	padding: 70px 0;
}

@-webkit-keyframes fadeInCheckbox {
	from {
		opacity: 0;
		-webkit-transform: rotateZ(-20deg);
	}
	to {
		opacity: 1;
		-webkit-transform: rotateZ(0deg);
	}
}

@keyframes fadeInCheckbox {
	from {
		opacity: 0;
		transform: rotateZ(-20deg);
	}
	to {
		opacity: 1;
		transform: rotateZ(0deg);
	}
}
</style>